import React from "react";
import Layout from "../../Layout";
import data from "./data";
import "./style.scss";
import SEO from "../../seo";

export default function Alumni() {
  return (
    <Layout>
      <SEO
        title="Alumni Network | Chinmaya Vidyalaya Vasant Vihar"
        description="The School’s highly accomplished alumni are leaders in varied fields and play an active role in the Vidyalaya’s activities. Register to get Alumni updates or read messages from the school's alumni."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
      />
      <div className="alumni">
        <h1 className="heading">Alumni</h1>

        <h4>
          "The success of an Institution can be measured by the contribution its
          alumni make to the Nation."
        </h4>

        <p>
          Chinmaya Vidyalaya has been on the path of creating rich human
          resource for the Nation. It boasts of extremely accomplished alumni
          functioning in varied fields like Civil services, Medical,
          Engineering, Art and Culture etc.
          <br />
          <br />
          The alumni are actively involved in the Vidyalaya activities. They are
          invited for conducting workshops and sharing their expertise in their
          respective fields. Workshops have been conducted for students in the
          fields of Career Counselling, Dance, Music, Photography etc. They
          contribute articles and other content for the school magazine as some
          of them are writers, bloggers and content creators. The alumni have an
          active involvement in the school annual reunion.
          <br />
          <br />
          The Vidyalaya also invites accomplished alumni as Chief Guest for the
          Annual Prize Distribution Ceremony.
        </p>

        <div className="alumni__button">
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=acl5HldhfUylAiTLr0m9z-kpETiTqSlPoA7KeHqeo2VUMUI5N083WDBLOUFTTzQ0WU9ONVE5SkxYTC4u"
            target="_blank"
            className="btn-dark"
          >
            Registration
          </a>
        </div>
        <div className="alumni__button">
          <a href="https://bit.ly/4dFRqPI" target="_blank" className="btn-dark">
            Chinmaya Vidyalaya New Delhi <br />
            Alumni Group (All Batches)
          </a>
        </div>

        <div className="alumni__messages">
          <h2 className="heading--white">Message From Our Alumni</h2>

          <div className="alumni__messages__container">
            <div className="alumnirow">
              {data.map((item) => (
                <div className="alumni__messages__card">
                  <div>
                    <div className="alumni__messages__card__msg">
                      {item.msg}
                    </div>
                    <div className="alumni__messages__card__name">
                      {item.name}
                    </div>
                    <div className="alumni__messages__card__batch">
                      Batch of {item.batch}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
